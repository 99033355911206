import React, { useRef } from "react";
import Head from "../../components/meta/Meta";
import {
  useFadeInAnimation,
  useRotateOnceAnimation,
} from "../../components/animations/useCustomAnimation";
import { useSelector } from "react-redux";

const FollowersForInfluencers = () => {
  const rotateOnceRef = useRef(null);
  const fadeInRef = useRef(null);

  useRotateOnceAnimation(rotateOnceRef, 2, 0);
  useFadeInAnimation(fadeInRef, 1, 0);

  const user = useSelector((state) => state.user.user);

  return (
    <div className="followers-for-influencers" ref={fadeInRef}>
      <Head
        title="Followers for Influencers | Earn by Engaging With TNI"
        desc="Join TNI to become a valuable follower for influencers. Engage with posts, support your favorite influencers, & earn rewards for this. Start earning today!"
      />
      <div className="brand-influencer">
        {/* Hero Section */}
        <section className="brand-influencer__hero">
          <div className="brand-influencer__text-section">
            <h1 className="brand-influencer__header">
              Followers for Influencers – Earn by Engaging with Your Favorite
              Influencers
            </h1>
            <p className="brand-influencer__description">
              Are you ready to turn your interactions into rewards? At{" "}
              <a href="/" style={{ textDecoration: "none" }}>
                Top Notch Influencer
              </a>
              , we give you the chance to earn money just by engaging with the
              influencers you love. Whether you’re liking, commenting, or
              sharing posts, your participation directly contributes to
              influencer campaigns, and you get rewarded for it. Join our
              platform today and start earning for your engagement!
            </p>
          </div>
          <div className="brand-influencer__img">
            <img
              src="https://img.freepik.com/free-vector/new-employee-concept-illustration_114360-8899.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid"
              alt="Engaging with Influencers"
              className="brand-influencer__img--responsive"
            />
          </div>
        </section>

        {/* How It Works Section */}
        <section className="brand-influencer__section">
          <h2 className="brand-influencer__section-header">How It Works</h2>
          <ul className="brand-influencer__steps">
            <li>
              <b>Sign Up</b> – Create your free account and explore influencer
              content in your favorite niches.
            </li>
            <li>
              <b>Engage</b> – Like, comment, and interact with ads and posts
              from influencers. The more you engage, the more you grow your
              potential earnings.
            </li>
            <li>
              <b>Earn</b> – Each interaction helps the influencer and the
              business, and you get a share of the rewards when their campaign
              succeeds. Watch your earnings grow as you contribute to their
              success!
            </li>
          </ul>
        </section>

        {/* Benefits Section */}
        <section className="brand-influencer__section">
          <h2 className="brand-influencer__section-header">
            Benefits of Engagement
          </h2>
          <ul className="brand-influencer__benefits">
            <li>
              <strong>Real Followers for Influencers:</strong> Help your
              favorite influencers increase their genuine follower base by
              supporting their posts.
            </li>
            <li>
              <strong>Engagement Growth for Followers:</strong> As you engage
              with content, your interactions become valuable, making you an
              essential part of the influencer marketing ecosystem.
            </li>
            <li>
              <strong>Followers Growth for Influencers:</strong> Your engagement
              directly influences the growth of influencers, helping them expand
              their reach and success.
            </li>
          </ul>
        </section>

        {/* FAQ Section */}
        <section className="brand-influencer__section">
          <h2 className="brand-influencer__section-header">
            Frequently Asked Questions
          </h2>
          <ul className="brand-influencer__stories">
            <li>
              <strong>How do I earn?</strong> By liking, commenting, and sharing
              influencer content, you contribute to the success of their
              campaigns. If those campaigns generate sales or leads, a portion
              of the revenue is shared with you.
            </li>
            <li>
              <strong>How much can I make?</strong> The amount you earn depends
              on the success of the influencer’s campaign and your level of
              engagement. The more you interact, the higher your potential
              rewards.
            </li>
            <li>
              <strong>Can I choose which influencers to follow?</strong>{" "}
              Absolutely! You have the freedom to follow and engage with
              influencers that resonate with your interests.
            </li>
          </ul>
        </section>

        {/* Call to Action Section */}
        <section className="brand-influencer__cta">
          <h2>
            Ready to support your favorite influencers while earning rewards?
          </h2>
          <p>
            Sign up and start engaging today! Expand your influence while
            helping influencers and brands grow their presence in the market.
          </p>
          <button className="influencer-collaboration__signup-button">
            <a
              href="/follower-reg"
              style={{
                textDecoration: "none",
                color: "#000",
                padding: 10,
              }}
            >
              Get Started Today!
            </a>
          </button>
        </section>
      </div>
    </div>
  );
};

export default FollowersForInfluencers;
