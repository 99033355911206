import axios from "axios";

export const Api = axios.create({
  baseURL: "https://api.topnotchinfluencer.com/api",
});

Api.interceptors.response.use(
  async (response) => response,
  (error) => Promise.reject(error.response.data)
);

export const setAxiosToken = () => {
  const token = localStorage.getItem("token");
  //console.log("Retrieved token from localStorage:", token);

  return Api.interceptors.request.use(
    async (config) => {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const publicUrl =
  "https://api.topnotchinfluencer.com/storage/app/public/";
