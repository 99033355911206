import React, { useEffect, useState } from "react";
import "./InfluencerCollaboration.css";
import Head from "../../components/meta/Meta";
import Carousel from "react-multi-carousel";

const testimonials = [
  {
    id: 1,
    name: "George Peters",
    quote:
      "Working with wellness brands helped me grow my follower base significantly and achieve substantial revenue from sponsored content.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid",
  },
  {
    id: 2,
    name: "Williams Joseph",
    quote:
      "Collaborating with boutique hotels and micro influencers increased engagement across multiple campaigns, making each collaboration a success.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid",
  },
  {
    id: 3,
    name: "Sarah Collins",
    quote:
      "Partnering with eco-friendly brands allowed me to connect with a highly engaged audience who shared my values.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid",
  },
  {
    id: 4,
    name: "John Doe",
    quote:
      "Promoting sports brands helped me achieve higher visibility and foster stronger relationships with my followers.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid",
  },
];

const InfluencerCollaboration = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 2) % testimonials.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="influencer-collaboration">
      {/* Hero Section */}

      <Head
        title={"Influencer Collaboration with Brands | Top Notch Influencer"}
        desc="Collaborate with top brands and grow your influence. TNI connects influencers with opportunities to create real campaigns that engage followers & drive growth."
      />
      <section className="influencer-collaboration__hero">
        <div className="influencer-collaboration__text-section">
          <h1 className="influencer-collaboration__header">
            Influencer Collaboration with Brands to Maximize Your Earnings
          </h1>
          <p className="influencer-collaboration__description">
            At Top Notch{" "}
            <a href="/" style={{ textDecoration: "none" }}>
              Influencer
            </a>
            , we empower creators to transform their influence into income. By
            collaborating directly with{" "}
            <a
              href="/brands-looking-for-influencers"
              style={{ textDecoration: "none" }}
            >
              brands
            </a>
            , you can create meaningful content, grow your following, and earn a
            significant share of the revenue. Our platform simplifies the
            process, making influencer collaboration with brands seamless and
            rewarding.
          </p>
        </div>
        <div className="influencer-collaboration__img">
          <img
            src="https://img.freepik.com/free-vector/signing-contract-official-document-agreement-deal-commitment-businessmen-cartoon-characters-shaking-hands-legal-contract-with-signature-concept-illustration_335657-2040.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid"
            alt="Influencer collaboration"
            className="influencer-collaboration__img--responsive"
          />
        </div>
      </section>

      {/* How It Works Section */}
      <section className="influencer-collaboration__section">
        <h2 className="influencer-collaboration__section-header">
          How It Works
        </h2>
        <ul className="influencer-collaboration__steps">
          <li>
            <b>Find Brand Opportunities</b> – Explore brand deals for
            influencers in various industries, including brands that work with
            micro influencers for niche marketing opportunities.
          </li>
          <li>
            <b> Create Impactful Content</b> – Once you’re connected with a
            brand, create content that aligns with their campaign goals and
            speaks to your audience.
          </li>
          <li>
            <b>Earn 99% Revenue</b> – For every lead or sale your campaign
            generates, you’ll keep 99% of the revenue—allowing you to maximize
            your income while maintaining creative freedom.
          </li>
          <li>
            <b>Expand Your Influence</b> – Every successful collaboration
            increases your visibility and attracts more brand deals, helping you
            build your reputation as a top influencer.
          </li>
        </ul>
      </section>

      {/* Platform Features Section */}
      <section className="influencer-collaboration__section">
        <h2 className="influencer-collaboration__section-header">
          Platform Features
        </h2>
        <ul className="influencer-collaboration__features">
          <li>
            <strong>Advanced Analytics:</strong> Monitor your campaign’s success
            with real-time analytics that give you insights into engagement,
            conversions, and overall performance.
          </li>
          <li>
            <strong>Simple Content Management:</strong> Easily manage your
            campaigns, from content creation to scheduling, using our
            user-friendly content management system.
          </li>
          <li>
            <strong>Seamless Communication:</strong> Collaborate directly with
            brands through our influencer collaboration platform, ensuring
            smooth execution from start to finish.
          </li>
          <li>
            <strong>Reliable Payments:</strong> Get paid quickly and securely,
            with 99% of earnings going directly to you after every successful
            campaign.
          </li>
        </ul>
      </section>

      {/* Why Join Us Section */}
      <section className="influencer-collaboration__section">
        <h2 className="influencer-collaboration__section-header">
          Why Join Us
        </h2>
        <ul className="influencer-collaboration__benefits">
          <li>
            <strong>High Revenue Share:</strong> With one of the highest revenue
            splits in the industry, you retain 99% of your earnings.
          </li>
          <li>
            <strong>Direct Brand Access:</strong> Get matched with brands that
            align with your style, and work together on meaningful campaigns.
          </li>
          <li>
            <strong>Supportive Community:</strong> Whether you’re a seasoned
            creator or just starting, we offer resources and support to help you
            succeed and grow.
          </li>
        </ul>
      </section>

      {/* Success Stories Section */}
      <section className="influencer-collaboration__section">
        <h2 className="influencer-collaboration__section-header">
          Success Stories
        </h2>
        <Carousel
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 3,
            },
            desktop: {
              breakpoint: { max: 3000, min: 1000 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 464, min: 464 },
              items: 1,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          }}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="transform 0.5s ease-in-out"
        >
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="influencer-collaboration__testimonial-card"
            >
              <div className="influencer-collaboration__image">
                <img
                  src={testimonial.image}
                  alt={`Influencer ${testimonial.name}`}
                />
              </div>
              <div className="influencer-collaboration__content">
                <h3 className="influencer-collaboration__name">
                  {testimonial.name}
                </h3>
                <p className="influencer-collaboration__quote">
                  {testimonial.quote}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </section>
      {/* Call to Action Section */}
      <section className="influencer-collaboration__cta">
        <h2>Ready to Start Your Influencer Journey?</h2>
        <p>
          Join today and take your influencer collaboration with brands to the
          next level. Whether you’re looking for{" "}
          <a href="/brands-looking-for-influencers" style={{ color: "white" }}>
            brand deals{" "}
          </a>
          for influencers or focused on niche markets, our platform offers the
          support and tools you need to succeed.
        </p>
        <button className="influencer-collaboration__signup-button">
          <a
            href="/influencer-reg"
            style={{
              textDecoration: "none",
              color: "#000",
              padding: 10,
            }}
          >
            Join the Top Influencers Now!
          </a>
        </button>
      </section>
    </div>
  );
};

export default InfluencerCollaboration;
