import React from "react";
import { Helmet } from "react-helmet";

function Head({ title, desc, image }) {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content="react, helmet, meta" />

        {/* Open Graph tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={currentUrl} />
      </Helmet>
    </div>
  );
}

export default Head;
