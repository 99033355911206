// useCustomAnimations.js
import { useEffect } from "react";
import "./Animation.css";

export const useFadeInAnimation = (elementRef, duration = 5, delay = 5) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `fadeIn ${duration}s ease-in-out ${delay}s both`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useSlideInFromLeftAnimation = (
  elementRef,
  duration = 1,
  delay = 0
) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `slideInFromLeft ${duration}s ease-in-out ${delay}s both`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useZoomInAnimation = (elementRef, duration = 1, delay = 0) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `zoomIn ${duration}s ease-in-out ${delay}s both`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useSlideInFromBottomAnimation = (
  elementRef,
  duration = 1,
  delay = 0
) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `slideInFromBottom ${duration}s ease-in-out ${delay}s both`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useRotate360Animation = (
  elementRef,
  duration = 2,
  interval = 3
) => {
  useEffect(() => {
    const element = elementRef.current;
    let animationInterval;

    if (element) {
      const startAnimation = () => {
        element.style.animation = `rotate360 ${duration}s linear infinite`;
      };

      const stopAnimation = () => {
        element.style.animation = "";
      };

      startAnimation(); // Start the animation immediately

      animationInterval = setInterval(() => {
        if (element.style.animation) {
          stopAnimation();
        } else {
          startAnimation();
        }
      }, 3 * 1000);
    }

    return () => {
      if (animationInterval) {
        clearInterval(animationInterval);
      }
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, interval]);
};
export const useRotateOnceAnimation = (elementRef, duration = 2, delay = 0) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `rotateOnce ${duration}s linear ${delay}s forwards`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useSlideInFromRightAnimation = (
  elementRef,
  duration = 1,
  delay = 0
) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `slideInFromRight ${duration}s ease-in-out ${delay}s both`;
    }

    return () => {
      if (element) {
        element.style.animation = "";
      }
    };
  }, [elementRef, duration, delay]);
};

export const useScaleAnimation = (elementRef, duration = 3) => {
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = `scaleUp ${duration * 2}s ease-in-out infinite`;

      return () => {
        element.style.animation = "";
      };
    }
  }, [elementRef, duration]);
};
