import { createSlice } from "@reduxjs/toolkit";
import { setAxiosToken } from "../../axios";

const initialState = {
  user: null,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },

    register: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },

    role: (state, action) => {
      state.role = action.payload;
      state.isAuthenticated = true;
    },

    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;

      // Clear user data from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
});

export const { login, logout, register, role } = userSlice.actions;
export default userSlice.reducer;
