import React, { useRef } from "react";
import Head from "../../components/meta/Meta";
import {
  useFadeInAnimation,
  useRotateOnceAnimation,
} from "../../components/animations/useCustomAnimation";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; // Don't forget to import the styles

import "./BrandInfluencer.css";

const testimonials = [
  {
    id: 1,
    name: "Favour James",
    quote:
      "The platform completely transformed our engagement metrics, helping us connect with our audience on a deeper level. We saw an incredible 150% increase in engagement, which far exceeded our expectations.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 2,
    name: "Moses",
    quote:
      "Our campaigns became far more effective with this tool. It generated thousands of high-quality conversions, proving to be a game-changer for our marketing efforts.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 3,
    name: "Alexandra Lee",
    quote:
      "Using this platform allowed us to significantly boost our brand awareness. We reached a much wider audience than ever before and established a strong brand presence.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 4,
    name: "Samuel Brown",
    quote:
      "We achieved a remarkable 200% increase in sales after integrating this solution. It was exactly what we needed to scale our business to new heights.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 5,
    name: "Nina Gomez",
    quote:
      "This tool helped us engage with over 50,000 users in a meaningful way. The feedback and interactions we received were overwhelmingly positive.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 6,
    name: "James Smith",
    quote:
      "Thanks to this incredible platform, our brand’s reach grew by an astounding 300%. We are now recognized as a leader in our industry.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 7,
    name: "Sophia Martinez",
    quote:
      "We achieved record-breaking engagement levels that we never thought were possible. This solution truly brought our brand closer to our customers.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
  {
    id: 8,
    name: "Lucy",
    quote:
      "This platform made a huge impact in the influencer market, allowing us to collaborate effectively and create campaigns that resonated with our target audience.",
    image:
      "https://img.freepik.com/free-vector/user-circles-set_78370-4704.jpg",
  },
];

const BrandInfluencer = () => {
  const rotateOnceRef = useRef(null);
  const fadeInRef = useRef(null);

  useRotateOnceAnimation(rotateOnceRef, 2, 0);
  useFadeInAnimation(fadeInRef, 1, 0);

  const user = useSelector((state) => state.user.user);

  return (
    <div className="brand-influencer" ref={fadeInRef}>
      <Head
        title="Brands Looking for Influencers | Top Notch Influencer"
        desc="Discover how TNI connects brands looking for influencers. Launch effective campaigns, collaborate with micro influencers, and boost your brand's visibility."
      />

      <div className="brand-influencer__hero">
        <div className="brand-influencer__text-section">
          <h1 className="brand-influencer__header">
            Brands Looking for Influencers? Find the Perfect Match for Your
            Brand Growth
          </h1>
          <p className="brand-influencer__description">
            Welcome to a platform designed to help brands looking for{" "}
            <a
              href="/influencer-collaboration-with-brands"
              style={{ textDecoration: "none" }}
            >
              influencers
            </a>{" "}
            connect with those who can amplify your brand’s message, reach your
            target audience, and boost engagement.
          </p>
        </div>
        <div className="brand-influencer__img" ref={rotateOnceRef}>
          <img
            src="https://img.freepik.com/free-vector/illustrated-business-person-meditating_52683-60757.jpg?uid=R27806234&ga=GA1.1.1852762410.1725342281&semt=ais_hybrid"
            alt="hero"
            className="brand-influencer__img--responsive"
          />
        </div>
      </div>

      <section className="brand-influencer__section">
        <h2 className="brand-influencer__section-header">How It Works</h2>
        <ul className="brand-influencer__steps">
          <li>
            <b>Sign Up </b>– Create an account and define your brand’s goals.
          </li>
          <li>
            <b>Find Influencers</b> – Discover influencers across industries.
          </li>
          <li>
            <b>Collaborate</b> – Engage in seamless communication.
          </li>
          <li>
            <b> Launch Campaigns</b> – Work with influencers to create
            compelling content.
          </li>
          <li>
            <b>Track Results</b> – Monitor engagement, reach, and ROI.
          </li>
        </ul>
      </section>

      <section className="brand-influencer__section">
        <h2 className="brand-influencer__section-header">Why Choose Us</h2>
        <ul className="brand-influencer__benefits">
          <li>
            <b>Targeted Promotions</b> – Find the right influencers.
          </li>
          <li>
            <b>Advanced Analytics</b> – Real-time campaign insights.
          </li>
          <li>
            <b>Ease of Use</b> – Effortless campaign management.
          </li>
        </ul>
      </section>

      <section className="brand-influencer__section">
        <h2 className="brand-influencer__section-header">
          Benefits for Your Brand
        </h2>
        <ul className="brand-influencer__benefits">
          <li>
            <b>Maximize ROI</b> – Get measurable results.
          </li>
          <li>
            <b>Broaden Reach</b> – Expand brand visibility.
          </li>
          <li>
            <b>Strengthen Brand Awareness</b> – Build credibility.
          </li>
        </ul>
      </section>

      {/* <section className="brand-influencer__section">
        <h2 className="brand-influencer__section-header">Success Stories</h2>
        <div className="brand-influencer__stories">
          <div className="brand-influencer__card">
            <div className="brand-influencer__icon">🚀</div>
            <div className="brand-influencer__content">
              <h3 className="brand-influencer__title">Case Study 1</h3>
              <p className="brand-influencer__description">
                Increased engagement by <span className="highlight">150%</span>.
              </p>
            </div>
          </div>
          <div className="brand-influencer__card">
            <div className="brand-influencer__icon">💼</div>
            <div className="brand-influencer__content">
              <h3 className="brand-influencer__title">Case Study 2</h3>
              <p className="brand-influencer__description">
                Generated thousands of{" "}
                <span className="highlight">conversions</span>.
              </p>
            </div>
          </div>

   
        </div>
      </section> */}

      <section className="influencer-collaboration__section">
        <h2 className="influencer-collaboration__section-header">
          Success Stories
        </h2>

        <Carousel
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 3000 },
              items: 3,
            },
            desktop: {
              breakpoint: { max: 3000, min: 1000 },
              items: 3,
            },
            tablet: {
              breakpoint: { max: 464, min: 464 },
              items: 1,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
            },
          }}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="transform 0.5s ease-in-out"
        >
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="influencer-collaboration__testimonial-card"
            >
              <div className="influencer-collaboration__image">
                <img
                  src={testimonial.image}
                  alt={`Influencer ${testimonial.name}`}
                />
              </div>
              <div className="influencer-collaboration__content">
                <h3 className="influencer-collaboration__name">
                  {testimonial.name}
                </h3>
                <p className="influencer-collaboration__quote">
                  {testimonial.quote}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </section>

      <section className="brand-influencer__cta">
        <h2>Ready to Grow Your Brand?</h2>
        <p>
          Join our platform and see how we can help you secure brand deals for
          influencers, collaborate efficiently, and drive real results.
        </p>

        <button className="influencer-collaboration__signup-button">
          <a
            href="/business-reg"
            style={{
              textDecoration: "none",
              color: "#000",
              padding: 10,
            }}
          >
            Sign Up to Start Your Campaign Today!
          </a>
        </button>
      </section>
    </div>
  );
};

export default BrandInfluencer;
