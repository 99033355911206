import React from "react";
import { useSelector } from "react-redux";
import { FacebookIcon, HomeIcon, TwitterIcon } from "../../assets/images/icons";
import "./Footer.css";

function Footer() {
  const user = useSelector((state) => state.user.user);
  const userRole = useSelector((state) => state.user.role);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>About</h3>
          <p>
            At Top Notch Influencer, we believe in the power of authentic
            connections to help brands grow. Our platform makes it easy for
            businesses to partner with influencers who truly resonate with their
            target audience, creating marketing campaigns that not only reach
            the right people but also inspire real engagement.
          </p>
          <p>
            We carefully curate a network of influencers who understand your
            brand’s vision and can deliver content that feels genuine to their
            followers. By focusing on quality over quantity, we ensure your
            marketing efforts build trust and deliver results that last. Whether
            you're looking to boost visibility or drive sales, we're here to
            help you succeed through real, impactful influencer collaborations.
          </p>
        </div>
        <div className="footer-column">
          <h3>Pages</h3>
          <ul>
            <li>
              <a href={user ? "/posts" : "/login"}>Posts</a>
            </li>
            {userRole == "business" && (
              <li>
                <a href={user ? "/influencers" : "/login"}>Influencers</a>
              </li>
            )}
            <li>
              <a href={user ? "/settings" : "/login"}>Settings</a>
            </li>
            <li>
              <a href={user ? "/analytics" : "/login"}>Analytics</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Information</h3>
          <ul>
            {/* <li>
              <a href="/">Our Team</a>
            </li> */}
            <li>
              <a href="/#contact">Contact Us</a>
            </li>

            <li>
              <a href="/#about-topnotch-influencer">About</a>
            </li>

            <li>
              <a href="/#influencer-marketing-platform-features">Features</a>
            </li>
            {/* <li>
              <a href="/">About</a>
            </li>
            <li>
              <a href="/">Services</a>
            </li> */}
            {/* <li>
              <a href="/">Blog</a>
            </li> */}
            {/* <li>
              <a href="/">Terms and Services</a>
            </li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h3>Privacy and Policies</h3>
          <ul>
            <li>
              <a href={"/privacy-policy"}>Privacy</a>
            </li>
            <li>
              <a href={"/terms"}>Terms</a>
            </li>
            {/* <li>
              <a href={"/contracts"}>Contracts</a>
            </li> */}
          </ul>
        </div>
        {/* <div className="footer-column">
          <h3>Contact</h3>
          <p>
            <HomeIcon style={{ fontSize: 12 }} /> New York, NY 10012, US
          </p>
     
        </div> */}
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2024</p>
        {/* <div className="social-icons">
          <a href="/">
            <FacebookIcon style={{ fontSize: 12, color: "#fff" }} />
          </a>
          <a href="/">
            <TwitterIcon style={{ fontSize: 12, color: "#fff" }} />
          </a>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
