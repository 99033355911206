import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://api.topnotchinfluencer.com/api",
});

// Request interceptor to add token and log it
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage or any other secure place
    const token = localStorage.getItem("token");
    // console.log("our token is: ", token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      //console.log(`Token used: ${token}`);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
